<template>
    <div>
        <v-container class="pub mx-auto">
            <div class="titles">
                <p class="tl pt-1">{{ $t("pub") }}</p>
            </div>

            <template v-if="loading">
                <v-layout row style="justify-content: center;" v-if="loading">
                    <v-flex xs12><v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions"></v-skeleton-loader></v-flex>
                    <v-flex xs12><v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions"></v-skeleton-loader></v-flex>
                    <v-flex xs12><v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions"></v-skeleton-loader></v-flex>
                </v-layout>
            </template>
            <template v-else v-for="item in data">
                <v-card class="mb-6 hidden-sm-and-down1 desktop" outlined elevation="3" rounded :key="item">
                    <v-list-item three-line>
                        <v-list-item-avatar rounded tile width="188" height="268" color="grey" ><v-img rounded :lazy-src="'https://alkafeel.net/publications/img/90/'+item.img" :src="'https://alkafeel.net/publications/img/200/'+item.img" ></v-img></v-list-item-avatar>
                        
                        <v-list-item-content>
                            <router-link :to="'/lib/'+item.id+'?lang='+$i18n.locale" style="text-decoration: none;">
                                <v-list-item-title class="mb-1 t1 card_title"> {{item.title}} </v-list-item-title>
                            </router-link>
                            <v-list-item-subtitle class="t1 card_det">{{item.det}}</v-list-item-subtitle>
                            <v-layout row>
                                <v-flex sm12 md4 pt-1 >
                                    <!-- <div class="card_det padding hidden-sm-and-down"  style="font-size:18px">{{$t('pubs_count')}}: {{item.cnt}}</div> -->
                                </v-flex>
                                <v-flex sm12 md8>
                                    <v-card-actions class="padding" :style="$vuetify.rtl ? 'justify-content: left' : 'justify-content: right'">
                                        <v-btn :to="'/lib/'+item.id+'?lang='+$i18n.locale" class="black--text action_btn mx-3 px-8 py-3"> {{$t('view_pubs')}} </v-btn>
                                        <a style="text-decoration: none;" :href="'https://alkafeel.net/publications/down/'+item.down"><v-btn class="black--text action_btn px-8 py-3" > <img style="padding:3px; height:20px" src="/assets/img/download.svg"> {{$t('download_latest_pub')}} </v-btn></a>
                                    </v-card-actions>
                                </v-flex>
                            </v-layout>
                        </v-list-item-content>

                    </v-list-item>
                </v-card>

                <v-card class="mb-6 mx-8 hidden-md-and-up1 mobile" outlined elevation="3" rounded :key="item">
                    <v-img rounded class="mx-auto mt-3 px-3" max-height="400" max-width="450" :lazy-src="'https://alkafeel.net/publications/img/90/'+item.img" :src="'https://alkafeel.net/publications/img/400/'+item.img" ></v-img>
                    <v-list-item three-line>
                        
                        <v-list-item-content class="text-center">
                            <router-link :to="'/lib/'+item.id+'?lang='+$i18n.locale" style="text-decoration: none;">
                                <v-list-item-title class="mb-1 t1 card_title_m"> {{item.title}} </v-list-item-title>
                            </router-link>
                            <v-list-item-subtitle class="t1 card_det_m">{{item.det}}</v-list-item-subtitle>
                            
                                    <v-layout row my-6>
                                        <v-flex xs12>
                                            <v-btn width="90%" :to="'/lib/'+item.id+'?lang='+$i18n.locale" class="black--text action_btn mx-3 px-8 py-3"> {{$t('view_pubs')}} </v-btn>
                                        </v-flex>
                                        <v-flex xs12 mt-2>
                                            <a style="text-decoration: none;" :href="'https://alkafeel.net/publications/down/'+item.down">
                                                <v-btn width="90%" class="black--text action_btn px-8 py-3"> 
                                                    <img style="padding:3px; height:20px" src="/assets/img/download.svg"> {{$t('download_latest_pub')}} 
                                                </v-btn>
                                            </a>
                                        </v-flex>
                                    </v-layout>
                                    
                        </v-list-item-content>

                    </v-list-item>
                </v-card>
            </template>
            

        </v-container>
    </div>
</template>

<script>
// import axios from "axios";
export default {
    data() {
        return {
            data: [],
            loading: true,
            attrs: {
                class: 'ma-3',
                boilerplate: false,
                elevation: 2,
            },
        }
    },
    created() {
        document.title =this.$t("pub"); 
        this.ini();
    },
    methods: {
        ini () {
            this.$axios.get('pub/getCats')
            .then (d => {
                this.loading = false;
                this.data = d.data;
                // console.log(d.data);
            })
        }
    }
}
</script>

<style scoped>
.pub {
  width: 100%;
  padding-top: 30px;
  max-width: 1260px;
}
.pub .titles {
    text-align: center;
}
.pub .titles p {
  color: #000;
  font-size: 45px;
  font-weight: 700;
  font-family: 'cairo_bold';
}
.card_title {
    font-size: 32px;
    font-weight: 700;
    color:#000
}
.card_det {
    font-size: 20px;
    font-weight: 700;
    line-height: 37px;
}
.card_det_m {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}
.card_title_m {
    font-size: 24px;
    font-weight: 600;
    color:#000
}
.padding {
    padding: 10px 20px
}
.action_btn {
    background: #FFF!important;
    border: solid 2px #B1BD52;
    font-weight: 18px!important;
    font-weight: 700;
}
.mobile {
    display: none;
}
@media (max-width: 768px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
}
</style>